import Layout from '@/views/layout/Layout'
import { ADMIN_VIEW } from '@/services/permissions/PermissionService'
import { printedForms } from '@/router/administration/printedForms'
import { reportForms } from '@/router/administration/reportForms'
import { dictionaries } from '@/router/administration/dictionaries'

export default {
  path: '/administration',
  name: 'administration',
  redirect: '/administration/printed-forms/list',
  component: Layout,
  meta: {
    title: 'Администрирование',
    permissions: [
      ADMIN_VIEW
    ]
  },
  children: [
    printedForms,
    reportForms,
    dictionaries
  ]
}

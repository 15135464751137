import request from '@/utils/request'

export function showDictionary (id, params) {
  return request({
    url: `/api/dictionary/${id}`,
    method: 'get',
    params
  })
}

export function indexDictionary (params) {
  return request({
    url: '/api/dictionary/',
    method: 'get',
    params
  })
}

export function treeDictionary (params) {
  return request({
    url: '/api/dictionary/tree/',
    method: 'get',
    params
  })
}

export function enumDictionary (params) {
  return request({
    url: '/api/dictionary/enum/',
    method: 'get',
    params
  })
}

export function allDictionary () {
  return request({
    url: '/api/dictionary/all/',
    method: 'get'
  })
}

export function storeDictionary (data) {
  return request({
    url: '/api/dictionary',
    method: 'post',
    data
  })
}

export function updateDictionary (id, data) {
  return request({
    url: `/api/dictionary/${id}`,
    method: 'put',
    data
  })
}

export function deleteDictionary (params) {
  return request({
    url: '/api/dictionary',
    method: 'delete',
    params
  })
}
